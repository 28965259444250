@tailwind base;
@tailwind components;
@tailwind utilities;


.DateRangePickerInput__withBorder {
  border-radius: 0.25rem;
  border: 1px solid #dbdbdb;
}

.DateInput {
  width: 100px;
}
.DateInput_input {
  font-weight: 200;
  font-size: 1rem;
  line-height: 0;
  padding: 8px 11px 8px;
}

.keyPhoto{
  position:relative;
  width: 100%;
  height: 120px;
}

.keyPhoto img
{
  width: 100%;
  object-fit:cover;
  height: 120px;
}

.bokun-blue-bg {
background-color: rgb(29, 87, 199)
}

.bokun-blue-bg:hover {
  background-color: rgb(19, 58, 132);
}

.leaflet-container{
  height: 100%;
}


